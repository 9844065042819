import {
  isTurboFrame,
  beginProgressBar,
  completeProgressBar,
  isHashNavigation,
  isSameRepo,
  waitForStylesheets,
  dispatchTurboReload
} from './utils'
import {getStoredShelfParamsForCurrentPage} from '../../notifications/v2/notification-shelf-referrer-params'
import {getCachedAttributes} from './cache'
import {startSoftNav} from '../../soft-nav/state'

document.addEventListener('turbo:click', function (event) {
  if (!(event.target instanceof HTMLElement)) return

  const frameContainer = event.target.closest('[data-turbo-frame]')
  if (frameContainer instanceof HTMLElement) {
    event.target.setAttribute('data-turbo-frame', frameContainer.getAttribute('data-turbo-frame') || '')
  }

  if (!(event instanceof CustomEvent)) return
  // https://github.com/hotwired/turbo/issues/539
  // If we are doing a hash navigation, we want to prevent Turbo from performing a visit
  // so it won't mess with focus styles.
  if (isHashNavigation(location.href, event.detail.url)) {
    event.preventDefault()
    // return early so we don't start a soft-nav
    return
  }

  // Don't frame navigate if going to a different repo.
  const repoContainer = event.target.closest('#js-repo-pjax-container')
  const url = new URL(event.detail.url, window.location.origin)
  if (repoContainer && frameContainer && !isSameRepo(url.pathname, location.pathname)) {
    dispatchTurboReload('repo_mismatch')
    event.target.removeAttribute('data-turbo-frame')
    event.preventDefault()
  }

  // Here is where ALL Turbo navigation starts. We start by emitting the `soft-nav:start` event with `turbo` as a mechanism.
  startSoftNav('turbo')
})

// Emulate `onbeforeunload` event handler for Turbo navigations to
// support warning a user about losing unsaved content
document.addEventListener('turbo:before-fetch-request', function (event) {
  const unloadMessage = window.onbeforeunload?.(event)

  if (unloadMessage) {
    const navigate = confirm(unloadMessage)
    if (navigate) {
      window.onbeforeunload = null
    } else {
      event.preventDefault()
    }
  }
})

document.addEventListener('turbo:before-fetch-request', event => {
  if (event.defaultPrevented) return

  const frame = event.target as Element
  if (isTurboFrame(frame)) {
    beginProgressBar()
  }

  // attach a Turbo specific header for visit requests so the server can track Turbo usage
  if (frame?.tagName === 'HTML') {
    const ev = event as CustomEvent
    ev.detail.fetchOptions.headers['Turbo-Visit'] = 'true'
  }

  // pass the notifications params through turbo requests so we properly show the shelf
  const params = getStoredShelfParamsForCurrentPage(event.detail.url.pathname)
  if (params) {
    const newParams = new URLSearchParams(event.detail.url.search)
    for (const [key, value] of Object.entries<string>(params)) {
      if (value) {
        newParams.set(key, value)
      }
    }
    event.detail.url.search = newParams.toString()
  }
})

type FetchRequest = {
  readonly delegate: FetchRequestDelegate
}
interface FetchRequestDelegate {
  requestErrored(request: FetchRequest, error: Error): void
}
type FrameElement = {
  readonly delegate: FrameElementDelegate
}
type FrameElementDelegate = unknown

// TODO: turbo upstream will emit this event eventually https://github.com/hotwired/turbo/pull/640
// and we can remove the types above
const frame = document.createElement('turbo-frame') as unknown as FrameElement
const controllerPrototype = Object.getPrototypeOf(frame.delegate)
const originalRequestErrored = controllerPrototype.requestErrored
controllerPrototype.requestErrored = function (request: FetchRequest, error: Error) {
  this.element.dispatchEvent(
    new CustomEvent('turbo:fetch-error', {
      bubbles: true,
      detail: {request, error}
    })
  )
  return originalRequestErrored.apply(this, request, error)
}

// when a frame fetch request errors due to a network error
// we reload the page to prevent hanging the progress bar indefinitely
document.addEventListener('turbo:fetch-error', event => {
  // we don't want to reload the page due to an error on a form
  // since we might throw away the users work or submit the form again
  // other handling would be needed for this use case
  if (event.target instanceof HTMLFormElement) {
    return
  }

  const fetchRequest = (event as CustomEvent).detail.request

  window.location = fetchRequest.location
  event.preventDefault()
})

document.addEventListener('turbo:before-fetch-response', event => {
  const fetchResponse = (event as CustomEvent).detail.fetchResponse

  // Turbo is misbehaving when we Drive to our 404 page, so we
  // can force a reload if the response is 404 and prevent Turbo
  // from continuing.
  if (fetchResponse.statusCode === 404) {
    dispatchTurboReload('404')
    window.location = fetchResponse.location
    event.preventDefault()
  }
})

document.addEventListener('turbo:frame-render', event => {
  if (isTurboFrame(event.target)) {
    completeProgressBar()
  }
})

// copy over new attributes on <html> to the existing page
document.addEventListener('turbo:before-render', async event => {
  if (!(event instanceof CustomEvent)) return

  event.preventDefault()

  await waitForStylesheets()

  event.detail.resume()

  const newDocument = event.detail.newBody.ownerDocument.documentElement
  const currentDocument = document.documentElement

  for (const attr of currentDocument.attributes) {
    if (!newDocument.hasAttribute(attr.nodeName) && attr.nodeName !== 'aria-busy') {
      currentDocument.removeAttribute(attr.nodeName)
    }
  }

  for (const attr of newDocument.attributes) {
    if (currentDocument.getAttribute(attr.nodeName) !== attr.nodeValue) {
      currentDocument.setAttribute(attr.nodeName, attr.nodeValue!)
    }
  }
})

window.addEventListener('popstate', () => {
  const currentDocument = document.documentElement
  const cachedAttritbues = getCachedAttributes()

  if (!cachedAttritbues) return

  for (const attr of currentDocument.attributes) {
    if (!cachedAttritbues.find(cached => cached.nodeName === attr.nodeName)) {
      currentDocument.removeAttribute(attr.nodeName)
    }
  }

  for (const attr of cachedAttritbues) {
    if (currentDocument.getAttribute(attr.nodeName) !== attr.nodeValue) {
      currentDocument.setAttribute(attr.nodeName, attr.nodeValue!)
    }
  }
})
