import {
  endSoftNav,
  failSoftNav,
  initSoftNav,
  succeedSoftNav,
  SOFT_NAV_STATE,
  renderedSoftNav
} from '../../soft-nav/state'
import {hasSoftNavFailure, inSoftNav, setSoftNavFailReason, setSoftNavMechanism} from '../../soft-nav/utils'
import {setDocumentAttributesCache} from './cache'
import {beginProgressBar, completeProgressBar, markTurboHasLoaded} from './utils'

// In case this event is triggered, it means we are in a Frame navigation, so we update the mechanism (if needed).
document.addEventListener('turbo:frame-load', () => {
  // When going to a React page, there is a chance that the soft-nav end event finishes before the frame-load event.
  // In that case, we don't want to start a new soft-nav event here, so we'll skip this if the soft-nav has already ended.
  if (inSoftNav()) setSoftNavMechanism('turbo.frame')
  // When navigating using frames, we either render here, or wait for the react-app to render.
  renderedSoftNav({skipIfGoingToReactApp: true, allowedMechanisms: ['turbo.frame']})
})

// Turbo navigations should end here, unless we are navigating to a React app. In that case, React itself will
// end the navigation, since Turbo doesn't know when React is done rendering.
document.addEventListener('turbo:load', event => {
  markTurboHasLoaded()
  const isHardLoad = Object.keys((event as CustomEvent).detail.timing).length === 0

  if (inSoftNav() && !isHardLoad && !hasSoftNavFailure()) {
    // When navigating using drive, we either render here, or wait for the react-app to render.
    renderedSoftNav({skipIfGoingToReactApp: true, allowedMechanisms: ['turbo']})
    // If going to a react app, we let React succeed the soft-nav.
    succeedSoftNav({skipIfGoingToReactApp: true, allowedMechanisms: ['turbo', 'turbo.frame']})
  } else if (isHardLoad && (hasSoftNavFailure() || inSoftNav())) {
    // If going to a react app, we let React fail the soft-nav.
    failSoftNav({skipIfGoingToReactApp: true, allowedMechanisms: ['turbo', 'turbo.frame']})
  } else if (isHardLoad) {
    initSoftNav()
  }
})

document.addEventListener('beforeunload', () => endSoftNav())

// Set the failure reason when we get a reload
document.addEventListener('turbo:reload', function (event) {
  if (!(event instanceof CustomEvent)) return

  setSoftNavFailReason(event.detail.reason)
})

// When navigating to a page with a hash param, we first load the page
// and then emulate a click to the link with the hash. Since this counts
// as a same-page hash navigation, Turbo will not cause a second load.
document.addEventListener(SOFT_NAV_STATE.SUCCESS, () => {
  if (location.hash === '') return

  const a = document.createElement('a')
  a.href = `#${location.hash.slice(1)}`
  a.click()
})

document.addEventListener(SOFT_NAV_STATE.END, setDocumentAttributesCache)

document.addEventListener(SOFT_NAV_STATE.PROGRESS_BAR.START, beginProgressBar)
document.addEventListener(SOFT_NAV_STATE.PROGRESS_BAR.END, completeProgressBar)
