import {navigator} from '@manuelpuyol/turbo'

type State = Record<string, unknown> & {
  turbo?: {restorationIdentifier: string}
}

// keep Turbo's history up to date with the browser's in case code calls native history API's directly
const patchHistoryApi = (name: 'replaceState' | 'pushState') => {
  const oldHistory = history[name]

  history[name] = function (this: History, state?: State, unused?: string, url?: string | URL | null) {
    // we need to merge the state from turbo with the state given to pushState in case others are adding data to the state
    function oldHistoryWithMergedState(
      this: History,
      turboState: State,
      turboUnused: string,
      turboUrl?: string | URL | null
    ) {
      oldHistory.call(this, {...state, ...turboState}, turboUnused, turboUrl)
    }

    navigator.history.update(
      oldHistoryWithMergedState,
      new URL(url || location.href, location.href),
      state?.turbo?.restorationIdentifier
    )
  }
}

patchHistoryApi('replaceState')
patchHistoryApi('pushState')
