import {SOFT_NAV_STATE, updateFrame} from '../../soft-nav/state'
import {getCachedNode, setCachedNode} from './cache'
import {
  addNewScripts,
  addNewStylesheets,
  getTurboCacheNodes,
  getChangedTrackedKeys,
  replaceElements,
  waitForStylesheets,
  dispatchTurboReload
} from './utils'

interface FetchResponse {
  responseHTML: Promise<string>
  location: Location
}

let frameNavigation = false
let fetchResponse: FetchResponse | null

document.addEventListener('turbo:before-fetch-response', event => {
  fetchResponse = (event as CustomEvent).detail.fetchResponse
})

// Before rendering the new page (frame), we need to make sure the body is ready with
// all the classes necessary. We do that by replacing the current body's classes with
// classes that come from the `turbo-body-classes` meta tag.
// We also are ready to add new scripts and stylesheets to the head, since that won't be
// modified by the frame render.
// We don't update the title here because it will be overridden by the frame.
// We also don't update transients because it will mess with the B/F cache.
document.addEventListener('turbo:before-frame-render', async event => {
  // preventDefault MUST be the first thing in this event, otherwise rendering will NOT be paused.
  event.preventDefault()

  const {resume, newFrame} = (event as CustomEvent).detail

  frameNavigation = true

  if (!fetchResponse) return

  const responseHTML = await fetchResponse.responseHTML
  const responseLocation = fetchResponse.location
  fetchResponse = null

  const parsedHTML = new DOMParser().parseFromString(responseHTML, 'text/html')

  const sourceFrame = event.target as HTMLElement
  const targetFrames = parsedHTML.querySelectorAll<HTMLElement>('turbo-frame')
  const matchingFrame = [...targetFrames].find(frame => frame.id === sourceFrame?.id)
  const changedKeys = getChangedTrackedKeys(parsedHTML)

  // if the frames or tracked elements don't match, force a reload to the destination page otherwise
  // the user will get an empty page or a page with the wrong assets.
  if (!matchingFrame || changedKeys.length > 0) {
    dispatchTurboReload(`tracked_element_mismatch-${changedKeys.join('-')}`)
    window.location = responseLocation
    return
  }

  addNewStylesheets(parsedHTML)
  addNewScripts(parsedHTML)
  replaceElements(parsedHTML)
  replaceFrameClasses(sourceFrame, matchingFrame)

  setCachedNode(responseLocation.href, getTurboCacheNodes(parsedHTML))

  // We have to treat stylesheets as a blocking resource, so we wait for them to be loaded before continuing
  // the frame render.
  await waitForStylesheets()

  resume()

  if (shouldScrollToTop(newFrame)) {
    window.scrollTo(0, 0)
  }

  // If we replace classes too early there may be some jitter when navigating to/from full-width pages.
  replaceBodyClassesFromRequest(parsedHTML)
})

// At this point, Turbo finished updating things from its snapshot, so we can manually
// updates whatever is necessary from the navigation.
document.addEventListener(SOFT_NAV_STATE.SUCCESS, () => {
  // This is a safeguard for back/forwards navigation. If the user clicks those buttons.
  // Turbo will NOT trigger `turbo:before-fetch-response`, which would make the `body` stale.
  // Since we are restoring a page from the cache, `turboPageNodes` will be populated, so it
  // will know what are the `body` classes to restore.
  replaceBodyClassesFromCachedNodes()

  if (!frameNavigation) return

  frameNavigation = false
  replaceTitle()
  replaceTransientTags()
  updateFrame()
})

const replaceBodyClassesFromRequest = (html: Document) => {
  const classes = html.querySelector<HTMLMetaElement>('meta[name=turbo-body-classes]')?.content

  if (!classes) return

  document.body.setAttribute('class', classes)
}

const replaceBodyClassesFromCachedNodes = () => {
  // If the navigation is a full-page reload, `turboPageNodes` will be reset
  // and this will be a noop.
  const classes = getCachedNode()?.bodyClasses

  if (!classes) return

  document.body.setAttribute('class', classes)
}

const replaceTitle = () => {
  const title = getCachedNode()?.title

  if (title) {
    document.title = title
  }
}

// Replace all `data-pjax-transient` elements
const replaceTransientTags = () => {
  const cached = getCachedNode()?.transients
  if (!cached) return

  for (const el of document.querySelectorAll('head [data-pjax-transient]')) {
    el.remove()
  }

  for (const el of cached) {
    // title, scripts and stylesheets have their own logic to be added
    // so here we'll only deal with the rest of the transient elements
    // This is just a safeguard in case someone adds `data-pjax-transient`
    // to one of those elements.
    if (!el.matches('title, script, link[rel=stylesheet]')) {
      el.setAttribute('data-pjax-transient', '')
      document.head.append(el)
    }
  }
}

const replaceFrameClasses = (oldFrame: HTMLElement | undefined, newFrame: HTMLElement) => {
  if (!oldFrame) return

  oldFrame.className = newFrame.className
}

const shouldScrollToTop = (frame: HTMLElement) =>
  frame.getAttribute('data-turbo-skip-scroll') !== 'true' && frame.getAttribute('data-turbo-action') === 'advance'
