import {attr, controller, target} from '@github/catalyst'
import {remoteForm} from '@github/remote-form'
import {WebauthnGetElement, State} from './webauthn-get'
import {
  initializeMobileAuthRequestStatusPoll,
  hidePromptAndShowErrorMessage as showGitHubMobileErrorState,
  resetPrompt as resetGitHubMobilePrompt
} from '../sessions/github-mobile-two-factor'
import {smsSending, smsError, smsSuccess} from '../sessions/two-factor'
import {requestSubmit} from '../form'

enum SudoCredentialOptionsElementState {
  WebAuthn = 'webauthn',
  Password = 'password',
  GitHubMobile = 'github-mobile',
  Totp = 'totp'
}

remoteForm('.js-send-auth-code', async (form, wants) => {
  smsSending()
  let response
  try {
    response = await wants.text()
  } catch (error) {
    const parsedError = JSON.parse(error.response.text)
    smsError(parsedError.error)
  }
  if (response) {
    smsSuccess()
  }
})
@controller
export class SudoCredentialOptionsElement extends HTMLElement {
  static attrPrefix = ''
  @attr webauthnAvailable: string
  @attr githubMobileAvailable: string
  @attr totpAvailable: string
  @attr githubMobilePromptUrl: string
  @attr githubMobileGenericErrorMessage: string
  @attr smsGenericErrorMessage: string
  @attr genericErrorMessage: string
  @attr totpSmsTriggerUrl: string
  @attr activeCredentialOption: SudoCredentialOptionsElementState

  @target flashErrorMessageContainer: HTMLElement
  @target flashErrorMessageText: HTMLElement
  @target webauthnContainer: HTMLElement
  @target githubMobileContainer: HTMLElement
  @target githubMobileLoading: HTMLElement
  @target githubMobileLanding: HTMLElement
  @target totpContainer: HTMLElement
  @target totpLanding: HTMLElement
  @target passwordContainer: HTMLElement
  @target githubMobileNoChallengeMessage: HTMLElement
  @target githubMobileChallengeMessage: HTMLElement
  @target githubMobileChallengeValue: HTMLElement

  @target webauthnNav: HTMLElement
  @target githubMobileNav: HTMLElement
  @target totpNav: HTMLElement
  @target totpResendNav: HTMLElement
  @target passwordNav: HTMLElement
  @target navContainer: HTMLElement

  @target webauthnGet: WebauthnGetElement
  @target loginField: HTMLInputElement
  @target passwordField: HTMLInputElement

  currentState: SudoCredentialOptionsElementState

  connectedCallback(): void {
    this.currentState = this.getInitialState()
    this.renderPrompt(true)
  }

  getInitialState(): SudoCredentialOptionsElementState {
    if (this.activeCredentialOption) {
      return this.activeCredentialOption
    }

    if (this.isWebAuthnAvailable()) {
      return SudoCredentialOptionsElementState.WebAuthn
    }

    if (this.isGitHubMobileAvailable()) {
      return SudoCredentialOptionsElementState.GitHubMobile
    }

    if (this.isTotpAvailable()) {
      return SudoCredentialOptionsElementState.Totp
    }

    return SudoCredentialOptionsElementState.Password
  }

  async renderPrompt(initialLoad = false): Promise<void> {
    this.resetPrompt()

    try {
      switch (this.currentState) {
        case SudoCredentialOptionsElementState.WebAuthn:
          this.renderWebauthnOption()
          break
        case SudoCredentialOptionsElementState.GitHubMobile:
          await this.renderGitHubMobileOption(initialLoad)
          break
        case SudoCredentialOptionsElementState.Totp:
          // landing only exists if SMS-based auth
          if (this.totpLanding !== undefined) {
            await this.renderTotpSmsOption(initialLoad)
          } else {
            this.renderTotpAppOption()
          }
          break
        case SudoCredentialOptionsElementState.Password:
        default:
          this.renderPasswordOption()
          break
      }
    } catch (e) {
      await this.handleUnexpectedPromptError(e, initialLoad)
      return
    }

    // we only show the "nav"/having problems section if there are multiple options available
    if (this.hasMultipleOptions()) {
      this.renderNavContainer()
    }
  }

  async handleUnexpectedPromptError(unexpectedError: Error, initialLoad: boolean) {
    let errorMessage = ''

    switch (this.currentState) {
      case SudoCredentialOptionsElementState.GitHubMobile:
        errorMessage = this.githubMobileGenericErrorMessage
        break
      case SudoCredentialOptionsElementState.Totp:
        errorMessage = this.totpLanding !== undefined ? this.smsGenericErrorMessage : this.genericErrorMessage
        break
      default:
        errorMessage = this.genericErrorMessage
    }

    // if we have an unexpected error, show a generic error message
    // and try the password prompt as a fallback to prevent the user from being presented with an empty prompt / no options
    if (unexpectedError && this.currentState !== SudoCredentialOptionsElementState.Password) {
      this.currentState = SudoCredentialOptionsElementState.Password
      await this.renderPrompt(initialLoad)
      if (!initialLoad) {
        this.showErrorMessage(errorMessage)
      }
      throw unexpectedError // re-throw the error so we can capture any unexpected errors with failbot/sentry
    }
  }

  resetPrompt(): void {
    this.hideErrorMessage()
    this.hideWebAuthn()
    this.hideGitHubMobile()
    this.hideTotp()
    this.hidePassword()
    this.safeSetElementVisibility(this.navContainer, false)
  }

  hideWebAuthn() {
    this.safeSetElementVisibility(this.webauthnContainer, false)
    this.safeSetElementVisibility(this.webauthnNav, false)
  }

  hideGitHubMobile() {
    this.safeSetElementVisibility(this.githubMobileContainer, false)
    this.safeSetElementVisibility(this.githubMobileNav, false)
    this.safeSetElementVisibility(this.githubMobileLoading, false)
    this.safeSetElementVisibility(this.githubMobileLanding, false)
  }

  hideTotp() {
    this.safeSetElementVisibility(this.totpContainer, false)
    this.safeSetElementVisibility(this.totpLanding, false)
    this.safeSetElementVisibility(this.totpNav, false)
    this.safeSetElementVisibility(this.totpResendNav, false)
  }

  hidePassword() {
    this.safeSetElementVisibility(this.passwordContainer, false)
    this.safeSetElementVisibility(this.passwordNav, false)
  }

  renderNavContainer() {
    if (this.isWebAuthnAvailable() && this.currentState !== SudoCredentialOptionsElementState.WebAuthn) {
      this.safeSetElementVisibility(this.webauthnNav, true)
    }
    if (this.isGitHubMobileAvailable() && this.currentState !== SudoCredentialOptionsElementState.GitHubMobile) {
      this.safeSetElementVisibility(this.githubMobileNav, true)
    }
    if (this.isTotpAvailable() && this.currentState !== SudoCredentialOptionsElementState.Totp) {
      this.safeSetElementVisibility(this.totpNav, true)
    }
    if (this.currentState !== SudoCredentialOptionsElementState.Password) {
      this.safeSetElementVisibility(this.passwordNav, true)
    }
    this.safeSetElementVisibility(this.navContainer, true)
  }

  renderWebauthnOption(): void {
    this.safeSetElementVisibility(this.webauthnContainer, true)
    this.webauthnGet?.setState(State.Ready)
  }

  async initiateGitHubMobile(): Promise<void> {
    this.safeSetElementVisibility(this.githubMobileLoading, true)
    this.safeSetElementVisibility(this.githubMobileLanding, false)
    this.safeSetElementVisibility(this.githubMobileContainer, false)

    try {
      await this.initiateGitHubMobileAuthRequest()
    } catch {
      this.mobileFailHandler(this.githubMobileGenericErrorMessage)
      return
    } finally {
      this.safeSetElementVisibility(this.githubMobileLoading, false)
      this.safeSetElementVisibility(this.githubMobileContainer, true)
    }
  }

  showGitHubMobileLanding(): void {
    this.safeSetElementVisibility(this.githubMobileLanding, true)
    this.safeSetElementVisibility(this.githubMobileLoading, false)
    this.safeSetElementVisibility(this.githubMobileContainer, false)
  }

  async renderGitHubMobileOption(initialLoad = false): Promise<void> {
    try {
      resetGitHubMobilePrompt()
    } catch {
      // ignore errors
    }

    if (initialLoad) {
      return this.showGitHubMobileLanding()
    } else {
      return this.initiateGitHubMobile()
    }
  }

  async prepareTotpSmsPrompt(): Promise<void> {
    this.safeSetElementVisibility(this.totpLanding, false)
    this.safeSetElementVisibility(this.totpContainer, true)

    try {
      await this.initiateTotpSmsRequest()
    } catch {
      this.showErrorMessage(this.smsGenericErrorMessage)
      return
    } finally {
      this.safeSetElementVisibility(this.totpResendNav, true)
    }
  }

  async renderTotpSmsOption(initialLoad = false): Promise<void> {
    if (initialLoad) {
      this.safeSetElementVisibility(this.totpContainer, false)
      this.safeSetElementVisibility(this.totpLanding, true)
    } else {
      this.prepareTotpSmsPrompt()
    }
  }

  renderTotpAppOption(): void {
    this.safeSetElementVisibility(this.totpContainer, true)
  }

  renderPasswordOption(): void {
    this.safeSetElementVisibility(this.passwordContainer, true)
    if (this.loginField) {
      this.loginField.focus()
    } else {
      this.passwordField?.focus()
    }
  }

  hasMultipleOptions(): boolean {
    return this.isWebAuthnAvailable() || this.isGitHubMobileAvailable() || this.isTotpAvailable()
  }

  isWebAuthnAvailable(): boolean {
    return this.webauthnAvailable === 'true'
  }

  isGitHubMobileAvailable(): boolean {
    return this.githubMobileAvailable === 'true'
  }

  isTotpAvailable(): boolean {
    return this.totpAvailable === 'true'
  }

  showWebauthn(): void {
    this.currentState = SudoCredentialOptionsElementState.WebAuthn
    this.renderPrompt()
  }

  showGitHubMobile(): void {
    this.currentState = SudoCredentialOptionsElementState.GitHubMobile
    this.renderPrompt()
  }

  showTotp(): void {
    this.currentState = SudoCredentialOptionsElementState.Totp
    this.renderPrompt()
  }

  showPassword(): void {
    this.currentState = SudoCredentialOptionsElementState.Password
    this.renderPrompt()
  }

  githubMobileRetry(e: Event): void {
    e.preventDefault()
    this.showGitHubMobile()
  }

  async initiateGitHubMobileAuthRequest(): Promise<void> {
    const url = this.githubMobilePromptUrl
    const csrfToken = (document.getElementById('sudo-credential-options-github-mobile-csrf') as HTMLInputElement).value
    const data = new FormData()
    // eslint-disable-next-line github/authenticity-token
    data.append('authenticity_token', csrfToken)

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: data
    })

    if (!response.ok) {
      this.showErrorMessage(this.githubMobileGenericErrorMessage)
      return
    }

    const json = await response.json()
    const hasChallenge = !!json.challenge

    this.safeSetElementVisibility(this.githubMobileNoChallengeMessage, !hasChallenge)
    this.safeSetElementVisibility(this.githubMobileChallengeMessage, hasChallenge)
    this.safeSetElementVisibility(this.githubMobileChallengeValue, hasChallenge)

    if (hasChallenge) {
      this.githubMobileChallengeValue.textContent = json.challenge
    }

    const el = document.getElementsByClassName('js-poll-github-mobile-sudo-authenticate')[0]
    initializeMobileAuthRequestStatusPoll(
      el,
      () => this.mobileApprovedHandler(),
      (message: string) => this.mobileFailHandler(message),
      () => this.mobileCancelCheck()
    )
  }

  async initiateTotpSmsRequest(): Promise<void> {
    const url = this.totpSmsTriggerUrl
    const csrfToken = (document.getElementById('sudo-credential-options-sms-csrf') as HTMLInputElement).value
    const data = new FormData()
    // eslint-disable-next-line github/authenticity-token
    data.append('authenticity_token', csrfToken)

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: data
    })
    if (!response.ok) {
      const responseJson = await response.json()
      this.showErrorMessage(responseJson.error)
      return
    }
  }

  mobileApprovedHandler(): void {
    const form = this.githubMobileContainer.getElementsByTagName('form')[0]
    requestSubmit(form)
  }

  mobileFailHandler(message: string): void {
    this.showErrorMessage(message)
    showGitHubMobileErrorState()
  }

  mobileCancelCheck(): boolean {
    return this.currentState !== SudoCredentialOptionsElementState.GitHubMobile
  }

  safeSetElementVisibility(elem: HTMLElement, visible: boolean): boolean {
    if (elem) {
      elem.hidden = !visible
      return true
    }
    return false
  }

  showErrorMessage(message: string): void {
    if (this.flashErrorMessageText) {
      this.flashErrorMessageText.textContent = message
      this.safeSetElementVisibility(this.flashErrorMessageContainer, true)
    }
  }

  hideErrorMessage(): void {
    if (this.flashErrorMessageText) {
      this.flashErrorMessageText.textContent = ''
    }
    this.safeSetElementVisibility(this.flashErrorMessageContainer, false)
  }
}
