// History back link
//
// DEPRECATED: please do not use this behavior in new places.
//
// Links tagged with "js-pjax-history-navigate" classname gain the behavior of popping the
// last item from the browser history stack if the link's href matches the URL of
// the last history item. This behavior is suitable for links whose purpose it so
// bring users back to the previous page.

import {getBackURL, getForwardURL} from '../../history'
import {endSoftNav} from '../../soft-nav/state'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {isTurboEnabled, isTurboFrame} from '../turbo/utils'

// okay to call `on` outside of global scope in this case as it's called conditionally to a feature flag
on('click', '.js-turbo-history-navigate', function (ev) {
  if (!isTurboEnabled()) return
  const event = ev as MouseEvent

  if (!(event.currentTarget instanceof HTMLAnchorElement)) return
  // eslint-disable-next-line no-restricted-syntax
  if (event.shiftKey || event.metaKey || event.ctrlKey || event.altKey) return

  const backURL = getBackURL()
  const forwardURL = getForwardURL()

  if (backURL && event.currentTarget.href === backURL) {
    event.preventDefault()
    cancelNextTurboFrameFetchRequest(backURL)
    history.back()
  } else if (forwardURL && event.currentTarget.href === forwardURL) {
    event.preventDefault()
    cancelNextTurboFrameFetchRequest(forwardURL)
    history.forward()
  }
})

// okay to call `on` outside of global scope in this case as it's called conditionally to a feature flag
on('pjax:click', '.js-pjax-history-navigate', function (event) {
  if (isTurboEnabled()) return
  if (!(event.currentTarget instanceof HTMLAnchorElement)) return
  if (event.currentTarget.href === getBackURL()) {
    history.back()
    event.detail.relatedEvent.preventDefault()
    event.preventDefault()
  } else if (event.currentTarget.href === getForwardURL()) {
    history.forward()
    event.detail.relatedEvent.preventDefault()
    event.preventDefault()
  }
})

function cancelNextTurboFrameFetchRequest(url: string) {
  const abortController = new AbortController()
  const {signal} = abortController

  document.addEventListener(
    'turbo:before-fetch-request',
    function (event) {
      if (!isTurboFrame(event.target)) return

      const fetchUrl = (event as CustomEvent).detail.url.href
      if (url !== fetchUrl) return

      event.preventDefault()
      endSoftNav()
    },
    {once: true, signal}
  )

  // prevent undetonated `cancelNextTurboFrameFetchRequest` handler from being restored if
  // the page is restored from bfcache
  window.addEventListener('pagehide', event => {
    if (event.persisted) {
      abortController.abort()
    }
  })
}
