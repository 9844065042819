import {onCLS, onFCP, onFID, onLCP, onTTFB, onINP} from 'web-vitals'
import type {Metric} from 'web-vitals'
import {loaded} from '../document-ready'
import {sendStats} from '../stats'
import {HPCTimingEvent} from './hpc'
import {getCurrentReactAppName} from '../soft-nav/utils'

type MetricOrHPC = Metric | HPCTimingEvent

export function sendVitals(metric: MetricOrHPC) {
  const {name, value} = metric
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stat: {[key: string]: any} = {
    name: window.location.href,
    app: getCurrentReactAppName() || 'rails',
    ui: Boolean(document.querySelector('meta[name="ui"]'))
  }
  stat[name.toLowerCase()] = value

  if (name === 'HPC') stat.soft = metric.soft

  sendStats({webVitalTimings: [stat as PlatformBrowserPerformanceWebVitalTiming]})
  updateStaffBar(name, value)
}

function updateStaffBar(name: string, value: number) {
  const staffBarContainer = document.querySelector('#staff-bar-web-vitals')
  const metricContainer = staffBarContainer?.querySelector(`[data-metric=${name.toLowerCase()}]`)

  if (!metricContainer) {
    return
  }

  metricContainer.textContent = value.toPrecision(6)
}

function isTimingSuppported(): boolean {
  return !!(window.performance && window.performance.timing && window.performance.getEntriesByType)
}

async function sendTimingResults() {
  if (!isTimingSuppported()) return

  await loaded
  await new Promise(resolve => setTimeout(resolve))

  const resourceTimings = window.performance.getEntriesByType(
    'resource'
  ) as unknown as PlatformBrowserPerformanceResourceTiming[]
  if (resourceTimings.length) {
    sendStats({resourceTimings})
  }

  const navigationTimings = window.performance.getEntriesByType(
    'navigation'
  ) as unknown as PlatformBrowserPerformanceNavigationTiming[]
  if (navigationTimings.length) {
    sendStats({navigationTimings})
  }
}

sendTimingResults()
onCLS(sendVitals)
onFCP(sendVitals)
onFID(sendVitals)
onLCP(sendVitals)
onTTFB(sendVitals)
onINP(sendVitals)
