import {loaded} from '../document-ready'
import {sendPageView} from '../hydro-analytics'
import {SOFT_NAV_STATE} from '../soft-nav/state'
import {getSoftNavMechanism} from '../soft-nav/utils'
;(async function () {
  // PJAX should be treated like pageloads
  document.addEventListener('pjax:complete', () =>
    sendPageView({
      pjax: 'true'
    })
  )

  // Turbo loads should be treated like pageloads
  document.addEventListener(SOFT_NAV_STATE.FRAME_UPDATE, () =>
    sendPageView({
      turbo: 'true'
    })
  )

  document.addEventListener(SOFT_NAV_STATE.SUCCESS, () => {
    if (getSoftNavMechanism() === 'turbo.frame') {
      return
    } else {
      sendPageView({
        turbo: 'true'
      })
    }
  })

  // Send a page view as soon as the page is loaded
  await loaded
  sendPageView()
})()
