import {onLCP} from 'web-vitals'
import {SOFT_NAV_STATE} from '../soft-nav/state'
import {sendVitals} from './timing-stats'

interface HPCEventTarget extends EventTarget {
  addEventListener(
    type: 'hpc:timing',
    listener: (event: HPCTimingEvent) => void,
    options?: boolean | AddEventListenerOptions
  ): void
  addEventListener(type: string, listener: (event: Event) => void, options?: boolean | AddEventListenerOptions): void
}

export class HPCTimingEvent extends Event {
  name: 'HPC' = 'HPC'
  value: number

  constructor(public soft: boolean, start: number) {
    super('hpc:timing')
    this.value = performance.now() - start
  }
}

// We use an AbortController to cleanup event listeners when we soft navigate.
let abortController = new AbortController()

// Restart HPC measurement. This will stop any ongoing HPC calls and start a new timer.
function resetHPC({soft = true}) {
  abortController.abort()
  abortController = new AbortController()

  // Initialize HPC
  const hpcStart = soft ? performance.now() : 0
  const hpc: HPCEventTarget = new EventTarget() as HPCEventTarget
  let tabHidden = false
  let animationFrame: number
  let dataHPCanimationFrame: number

  // Observer to listen to ALL mutations to the DOM. We need to check all added nodes
  // for the `data-hpc` attribue. If none are found, we keep listening until all mutations are done.
  const hpcDOMInsertionObserver = new MutationObserver(mutations => {
    let hasDataHPC = false

    // if the mutation didn't add any nodes, we don't track its HPC
    if (mutations.every(mutation => mutation.addedNodes.length === 0)) return

    cancelAnimationFrame(animationFrame)

    for (const mutation of mutations) {
      if (mutation.type !== 'childList') continue

      for (const node of mutation.addedNodes) {
        if (!(node instanceof Element)) continue

        if (node.hasAttribute('data-hpc') || node.querySelector('[data-hpc]')) {
          hasDataHPC = true
          break
        }
      }

      if (hasDataHPC) break
    }

    if (hasDataHPC) {
      // data-hpc found, we can stop listening to mutations.
      hpcDOMInsertionObserver.disconnect()
      // only cancel the animation frame if the controller aborts.
      dataHPCanimationFrame = requestAnimationFrame(() => {
        hpc.dispatchEvent(new HPCTimingEvent(soft, hpcStart))
      })
    } else {
      animationFrame = requestAnimationFrame(() => {
        hpc.dispatchEvent(new Event('hpc:dom-insertion'))
      })
    }
  })
  hpcDOMInsertionObserver.observe(document, {childList: true, subtree: true})

  // Stop listening for HPC events if the user has interacted, as interactions
  // can cause DOM mutations, which we want to avoid capturing for HPC.
  const listenerOpts = {capture: true, passive: true, once: true, signal: abortController.signal}
  const stop = () => abortController.abort()

  // eslint-disable-next-line github/require-passive-events
  document.addEventListener('touchstart', stop, listenerOpts)
  document.addEventListener('mousedown', stop, listenerOpts)
  document.addEventListener('keydown', stop, listenerOpts)
  document.addEventListener('pointerdown', stop, listenerOpts)

  let emulatedHPCTimer: ReturnType<typeof setTimeout>
  hpc.addEventListener(
    'hpc:dom-insertion',
    () => {
      clearTimeout(emulatedHPCTimer)
      // Whenever we see a DOM insertion, we keep track of when it happened.
      const event = new HPCTimingEvent(soft, hpcStart)

      // If no mutations happen for 2500ms, we assume that the DOM is fully loaded, so we send the
      // last seen mutation values.
      emulatedHPCTimer = setTimeout(() => hpc.dispatchEvent(event), 2500)
    },
    {signal: abortController.signal}
  )

  hpc.addEventListener(
    'hpc:timing',
    (e: HPCTimingEvent) => {
      if (!tabHidden && e.value < 60_000) sendVitals(e)

      abortController.abort()
    },
    {signal: abortController.signal}
  )

  // If the stop event is triggered, we want to stop listening to DOM mutations.
  abortController.signal.addEventListener('abort', () => {
    cancelAnimationFrame(dataHPCanimationFrame)
    cancelAnimationFrame(animationFrame)
    clearTimeout(emulatedHPCTimer)
    hpcDOMInsertionObserver.disconnect()
  })

  // If the user changes tab, we don't want to send the recorded metrics since it may send garbage data.
  document.addEventListener(
    'visibilitychange',
    () => {
      tabHidden = true
      abortController.abort()
    },
    {signal: abortController.signal}
  )

  // In a hard-load, if the script is evaluated after the `data-hpc` element is rendered,
  // we default the HPC value to LCP.
  if (!soft && document.querySelector('[data-hpc]')) {
    onLCP(({value}) => {
      sendVitals({name: 'HPC', value, soft} as HPCTimingEvent)
    })

    abortController.abort()
  }
}

// Any time we trigger a new soft navigation, we want to reset HPC.
document.addEventListener(SOFT_NAV_STATE.START, () => resetHPC({soft: true}))

// Start HPC at page load.
resetHPC({soft: false})
